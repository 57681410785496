import * as React from "react";
import TopMenu from "./TopMenu";
import Footer from "./Footer";
import { ReactNode } from "react";
import { Helmet } from "react-helmet"
// @ts-ignore
import appleTouchIcon from '../../static/favicon/apple-touch-icon.png';
// @ts-ignore
import icon32 from '../../static/favicon/favicon-32x32.png';
// @ts-ignore
import icon16 from '../../static/favicon/favicon-16x16.png';

type Props = {
    children: ReactNode
}

export default class Layout extends React.Component<Props> {
    render() {
        const title = 'Удобный софт';
        const description = 'Проектирование, разработка и техническое сопровождение информационных систем любой сложности';
        return (
            <>
                <Helmet>
                    <title>Удобный софт</title>
                    <link rel="apple-touch-icon" sizes="180x180" href={'/favicon/apple-touch-icon.png'}/>
                    <link rel="icon" type="image/png" sizes="32x32" href={'/favicon/favicon-32x32.png'}/>
                    <link rel="icon" type="image/png" sizes="16x16" href={'/favicon/favicon-16x16.png'}/>
                    <link rel="manifest" href={'/favicon/site.webmanifest'}/>
                    <link rel="mask-icon" href={'/favicon/safari-pinned-tab.svg" color="#5bbad5'}/>
                    <link rel="shortcut icon" href={'/favicon/favicon.ico'}/>
                    <meta name="msapplication-TileColor" content="#ffffff"/>
                    <meta name="msapplication-config" content={'/favicon/browserconfig.xml'}/>
                    <meta name="theme-color" content="#ffffff"/>
                    <meta itemProp="name" content={title}/>
                    <meta itemProp="description" content={description}/>
                    <meta itemProp="image" content={'https://comfortsoft.ru/og_comfortsoft.jpg'}/>
                    <meta property="og:site_name" content={title}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content={title}/>
                    <meta property="og:image" content={'https://comfortsoft.ru/og_comfortsoft.jpg'}/>
                    <meta property="og:description" content={description}/>
                </Helmet>
                <TopMenu/>
                <main>
                    {this.props.children}
                </main>
                <Footer/>
            </>
        )
    }
}
