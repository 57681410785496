import * as React from "react";
// @ts-ignore
import logoImage from '../../static/images/cs_logo.svg';
import { scrollToElementId } from "../helpers";

export default class TopMenu extends React.Component {
    menu = [
        {title: 'О компании', link: 'about'},
        {title: 'Услуги', link: 'services'},
        {title: 'Технологии', link: 'technologies'},
        {title: 'Проекты', link: 'projects'},
        {title: 'Контакты', link: 'contacts'},
    ];

    render() {
        return (
            <div className="top-menu">
                <div className="container">
                    <div className="logo">
                        <img src={logoImage} alt="Логотип Удобный Софт"/>
                    </div>
                    <nav>
                        {
                            this.menu.map((item, index) => {
                                return (
                                    <a key={index} title={item.title} onClick={(e) => scrollToElementId(item.link)}>{item.title}</a>
                                );
                            })
                        }
                    </nav>
                </div>
            </div>
        )
    }
}
