import * as React from "react";
//import shapeImage from '../../static/images/header_shape.svg';

export default class Footer extends React.Component {
    render() {
        const now = new Date();
        return (
            <footer>
                <div className="container">&copy; ООО &laquo;Удобный Софт&raquo;, {now.getFullYear()}</div>
            </footer>
        )
    }
}
